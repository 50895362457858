<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <QRReader v-if="!isFromLinePay" :camera="camera" @query="onQuery"></QRReader>
                <div v-else class="text-xs-center">
                    <img src="@/assets/logo.svg" />
                    <p class="subheading">{{ message }}</p>
                </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-1" id="pageTwo">
                <v-layout justify-center>
                    <v-flex xs12 sm10 md8 lg8>
                        <!-- <GoButton :showGoButtomBtn="showGoButtomBtn"/> -->
                        <door-selector
                            :enable="stepNo === 2"
                            :box-id="boxId"
                            :door-id.sync="doorId"
                            @select="selectDoorToOnePlan"
                            @back="preStep"
                        ></door-selector>
                        <v-subheader class="subheading pa-0">
                            {{ $t('putIn.plan-option') }}&nbsp;&nbsp;({{ plans.map(p => p.title).join(' / ') }})
                        </v-subheader>
                        <v-container v-if="doorInfo" fluid grid-list-md class="pa-0 pt-2">
                            <v-layout wrap>
                                <v-flex v-for="(plan, i) in plans" :key="i" xs12 sm6 md6 lg4>
                                    <plan-card
                                        :title="plan.title"
                                        :rate="plan.rate"
                                        :color="plan.color"
                                        :notes="plan.notes"
                                        height="95%"
                                        choseable
                                        @chose="chosePlan"
                                    ></plan-card>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-2">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <template v-if="targetPlan">
                            <v-subheader v-if="showPlan()" class="subheading">{{ $t('putIn.selected-plan') }}</v-subheader>
                            <plan-card v-if="showPlan()"
                                :title="targetPlan.title"
                                :rate="targetPlan.rate"
                                :color="targetPlan.color"
                                :notes="targetPlan.notes"
                            ></plan-card>
                        </template>
                        <PaymentInfo
                            :enable="stepNo === 3" 
                            :amount="amount"
                            :boxId="boxId"
                            :doorId="doorId"
                            rules
                        ></PaymentInfo>
                        <payment-selector
                            :enable="stepNo === 3"
                            :amount="amount"
                            :doorId="doorId"
                            :transType="transtype"
                            :action="action"
                            @back="setStepNo(2)"
                            @pay="callPutinAPI"
                            :emptyDialog.sync="emptyDialog"
                            ref="paymentSelector"
                        ></payment-selector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <v-card flat>
                            <v-card-text class="subheading">
                                <ul v-for="(item, index) in detail" :key=index class="nobull">
                                    <li :class="{ bold: (item.title === $t('putIn.cell-number')) }">{{ item.title }}&nbsp;&nbsp;{{ item.content }}</li>
                                </ul>
                            </v-card-text>
                        </v-card>
                        <electronic-money-detail v-if="isPayByElectronicMoney" :box-id="boxId" :detail="payinfo.detail"></electronic-money-detail>
                        <v-alert :value="true" type="warning" class="subheading">{{ $t('putIn.close-door') }}</v-alert>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn large block class="ma-1 white--text" color="purple darken-2" @click="changeReceiver">{{ $t('putIn.add-recipient') }}</v-btn>
                </v-layout>
                <v-layout justify-center>
                    <v-btn large outline class="body-2" color="success" @click="conti" data-cy="continueBtn">{{ $t('putIn.continue-use') }}</v-btn>
                    <v-btn large block class="ma-1" color="primary" @click="finish" data-cy="finishBtn">{{ $t('putIn.done') }}</v-btn>
                </v-layout>
            </v-stepper-content>
            <v-dialog persistent v-model="emptyDialog" width="250">
                <v-card class="text-xs-center">
                    <v-card-text class="title">
                        {{ dialogMessage }}
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn block class="subheading" color="primary" @click="leave()">{{ $t('putIn.close') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
        <receiver-dialog
            :show.sync="receiverDialog.show"
            :msg="receiverDialog.msg"
            :primary-btn="receiverDialog.primaryBtn"
            :secondary-btn="receiverDialog.secondaryBtn"
            @primary="closeReceiverDialogFunc"
            :receiverPhone.sync="receiverPhone"
        ></receiver-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store, { HostUrl } from '@/store'
import PaymentSelector from '@/components/payments/PaymentSelector'
import PaymentInfo from '@/components/payments/PaymentInfo'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import PutinMixin, { TransTypes } from '@/mixins/PutinMixin'
import ElectronicMoneyDetail from '@/components/payments/ElectronicMoneyDetail'
import ElectronicMoneyDetailMixin from '@/mixins/ElectronicMoneyDetailMixin'
import PlanCard from '@/components/PlanCard'
import { getAxiosConfig } from '@/utils/AuthService'
import { formatDatetime } from '@/utils/utils'
import i18n from '@/i18n'
import { LinePayType } from '@/components/payments/LinePay'
import { JKOPayType } from '@/components/payments/JKOPay'
import ReceiverDialog from '@/components/ReceiverDialog.vue'
import ReceiverDialogControl from '@/mixins/ReceiverDialogControl.vue'
// import { PaypalType } from '@/components/payments/Paypal'
// import GoButton from '@/components/GoButton'

export default {
    name: 'Putin',
    components: { PaymentSelector, PaymentInfo, ElectronicMoneyDetail, PlanCard, ReceiverDialog },
    mixins: [ BoxIdWatcherMixin, ControlResultWatcher, PutinMixin, ElectronicMoneyDetailMixin, ReceiverDialogControl ],
    data() {
        return {
            titles: [i18n.t('putIn.scan-code'), i18n.t('putIn.select-cell'), i18n.t('putIn.deduction'), i18n.t('putIn.put-items')],
            enablePayment: false,
            payedAmount: 0,
            transtype: TransTypes.Leave,
            sendTimes: 0,
            putinTime: null,
            putinUnixtime: null,
            targetPlan: null,
            emptyDialog: false,
            reloadPage: false,
            message: i18n.t('putIn.line-pay-transaction'),
            dialogMessage: i18n.t('putIn.cabinet-unavailable'),
            receiverPhone: "",
            closeReceiverDialogFunc: () => {}
        }
    },
    computed: {
        plans() {
            let plans = [
                {
                    title: i18n.t('putIn.temporary-storage'),
                    type: TransTypes.Leave,
                    amount: this.doorRate.startRate,
                    rate: `${this.doorRate.startRate} ${i18n.t('putIn.NTD')}${this.doorRate.startUnit} ${i18n.t('putIn.hr')}`,
                    color: 'blue-grey lighten-4',
                    notes: [
                        `${i18n.t('putIn.can-place')}${this.doorRate.startUnit} ${i18n.t('putIn.place-hours')}`,
                        `${i18n.t('putIn.after')}${this.doorRate.startUnit}${i18n.t('putIn.be-charged')}${this.doorRate.contiRate} ${i18n.t('putIn.NTD')}${this.doorRate.contiUnit} ${i18n.t('putIn.be-charged-hours')}`
                    ]
                }
            ]
            if (this.doorRate.originalRate > 0) {
                plans = [
                    {
                        title: i18n.t('putIn.temporary-storage'),
                        type: TransTypes.Leave,
                        amount: this.doorRate.startRate,
                        rate: `${this.doorRate.startRate} ${i18n.t('putIn.NTD')}${this.doorRate.startUnit} ${i18n.t('putIn.hr')}`,
                        color: 'blue-grey lighten-4',
                        notes: [
                            `${i18n.t('putIn.special_deduct')}${this.doorRate.startRate} ${i18n.t('clientPutIn.notes.NTD')}`+' '+`(${i18n.t('putIn.original_deduct')}${this.doorRate.originalRate})`,
                            `${i18n.t('putIn.can-place')}${this.doorRate.startUnit} ${i18n.t('putIn.place-hours')}`,
                            `${i18n.t('putIn.after')}${this.doorRate.startUnit}${i18n.t('putIn.be-charged')}${this.doorRate.contiRate} ${i18n.t('putIn.NTD')}${this.doorRate.contiUnit} ${i18n.t('putIn.be-charged-hours')}`
                        ]
                    }
                ]
            }
            if (this.doorRate.startRate == 0) {
                plans = [
                    {
                        title: i18n.t('putIn.temporary-storage'),
                        type: TransTypes.Leave,
                        amount: this.doorRate.startRate,
                        rate: `${this.doorRate.startRate} ${i18n.t('planPickerDialog.ntd')}`,
                        color: 'blue-grey lighten-4',
                        notes: [
                            `${i18n.t('putIn.can-free-place')}`,
                            `${i18n.t('putIn.after-overnight')}`
                        ]
                    }
                ]
            }
            if (this.doorRate.monthRate) {
                plans.push({
                    title: i18n.t('putIn.monthly-plan'),
                    type: TransTypes.LongLeave,
                    amount: this.doorRate.monthRate,
                    rate: `${this.doorRate.monthRate} ${i18n.t('putIn.NTD-month')}`,
                    color: 'cyan lighten-4',
                    notes: [
                        i18n.t('putIn.unlimited-times'),
                        `${i18n.t('putIn.after-lease')} ${this.doorRate.contiRate} ${i18n.t('putIn.NTD')}${this.doorRate.contiUnit} ${i18n.t('putIn.hours')}`
                    ]
                })
            }
            return plans
        },
        planNames() {
            return this.plans.map(p => p.title)
        },
        detail() {
            return [
                { title: i18n.t('putIn.cabinet-number'), content: this.boxId },
                { title: i18n.t('putIn.cell-number'), content: this.doorId },
                { title: i18n.t('putIn.transaction-number'), content: this.trackNo },
                { title: i18n.t('putIn.amount-debit'), content: this.payedAmount },
                { title: i18n.t('putIn.storage-time'), content: this.putinTime },
                // { title: i18n.t('putIn.start-time'), content: this.startChargeTime } 暫時註解掉第四頁開始計費時間
            ]
        },
        amount() {
            return this.targetPlan && this.targetPlan.amount || 0
        },
        startChargeTime() {
            if (this.rate && this.putinUnixtime) {
                let putin = new Date(this.putinUnixtime)
                putin.setHours(putin.getHours() + this.rate.start_unit)
                return formatDatetime(putin)

            //開始計費時間
            // } else if (this.putinUnixtime && this.isFromLinePay) {
            //     let putin = new Date(this.putinUnixtime)
            //     console.log('putin', putin)

            //     putin.setHours(putin.getHours() + this.rate.start_unit)
            //     console.log('putin', putin)

            //     return formatDatetime(putin)
            } else return ''
        }
    },
    methods: {
        selectDoorToOnePlan(door, rate){
            this.selectDoor(door, rate);
            // alert(`${door.id} ${JSON.stringify(rate)} ${this.plans.length}`)
            if (door == null) return
            if (this.plans.length === 1) {
                this.targetPlan = this.plans[0];
                this.nextStep()
            }
        },
        showPlan(){
            if (store.getters.electronicMoneyZeroMark && this.plans.length === 1) {
                this.transtype = this.plans[0].type
                return false
            } else return true
        },
        chosePlan(title) {
            this.targetPlan = this.plans.find(plan => plan.title === title)
            this.transtype = this.targetPlan.type
            this.nextStep()
        },
        callPutinAPI(payment) {
            this.payinfo = payment
            let url = ""
            let payload = {
                door_id: this.doorId,
                client_id: store.getters.clientId,
                trans_type: this.targetPlan.type,
                payment: payment,
                send_times: this.sendTimes
            }
            if (payment != null && payment.type === JKOPayType) {
                let JKOParameter = {
                    payload,
                    box_id: this.boxId,
                    from: 'putin'
                }
                store.commit('setJkoPayParameter', JKOParameter)
                this.$router.push(`/${i18n.locale}/jkopay`)
                this.init()
                return
            } else if (payment != null && payment.type === LinePayType) {
                let LinePayParameter = {
                    payload,
                    box_id: this.boxId,
                    from: 'putin'
                }
                store.commit('setLinePayParameter', LinePayParameter)
                this.$router.push(`/${i18n.locale}/linepay`)
                this.init()
                return
            }
            // else if (payment.type === PaypalType) {
            //     this.$router.push(`/${i18n.locale}/paypal`)
            //     return
            // } 
            else {
                url = HostUrl + `/api/box/${this.boxId}/putin`             
            }
            payload = {
                door_id: this.doorId,
                client_id: store.getters.clientId,
                trans_type: this.targetPlan.type,
                payment: payment,
                browser: navigator.userAgent
            }
            this.showDialog(i18n.t('putIn.trading'), i18n.t('putIn.transaction-failed'), 30000)
            let self = this;
            console.log('url::' + url + ', payload-payment::'+ payment)
            console.log('payload', payload)
            axios.post(url, payload, getAxiosConfig())
                .then(function(response) {
                    console.log('response', response)
                    if (response.data.code === 0) {
                        self.sendTimes++
                        if(response.data && response.data.payment_url && response.data.payment_url.web) {
                            window.location.href = response.data.payment_url.web
                        } else {
                            self.trackNo = response.data.track_no
                            self.payedAmount = response.data.amount
                            self.putinTime = response.data.putin_time
                            self.putinUnixtime = response.data.putin_unixtime
                        }
                    } else if (response.data.code === 508) {
                        if (response.data.message === '付款完成') response.data.message = i18n.t('putIn.payment-completed')
                            self.message = response.data.message
                            self.dialogMessage = response.data.message
                            self.emptyDialog = true
                            self.closeDialog()
                    } else if (response.data.code === 509) {
                        self.$refs.paymentSelector.$refs.directPay[0].payByPrime()
                        store.commit('setSnackBar', { message: i18n.t('putIn.transaction-fail'), color: 'error' })
                        self.closeDialog()
                    } else if (response.data.message.indexOf('[置物完成]') !== -1) {
                        self.message = response.data.message
                        self.dialogMessage = response.data.message
                        self.emptyDialog = true
                        self.reloadPage = true
                        self.closeDialog()
                    } else {
                        if (response.data.message === '密碼錯誤, 請使用註冊時，輸入的密碼') {
                            response.data.message = i18n.t('putIn.password-wrong')
                        }
                        if (response.data.message === '密碼錯誤, 請輸入信用卡前六碼') {
                            response.data.message = i18n.t('putIn.enter-six-digits')
                        }
                        if (response.data.message === '密碼錯誤, 請輸入設定的密碼') {
                            response.data.message = i18n.t('putIn.password-you-set')
                        }
                        store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                        self.closeDialog()
                    }
                })
                .catch(function() {
                    store.commit('setSnackBar', { message: i18n.t('electronicMoney.pay-fail'), color: 'error' })
                    self.closeDialog()
                })
        },
        async conti() {
            this.showDialog(i18n.t('putIn.upload-data'), i18n.t('putIn.upload-data-fail'))
            try {
                let response = await axios.post(HostUrl + '/api/staff/putin/continue', {
                    track_no: this.trackNo
                }, getAxiosConfig())
                if (response.data.code == 0) {
                    this.closeDialog()
                    this.showDialog(i18n.t('putIn.loading-data'), i18n.t('putIn.loading-data-fail'))
                    setTimeout(() => {
                        this.doorId = 0
                        this.doorInfo = null
                        this.setStepNo(2)
                        this.closeDialog()
                    }, 5000)
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    })
                    this.closeDialog()
                }
            } catch (error) {
                store.commit('setSnackBar', {
                    message: i18n.t('putIn.upload-data-fail-message'),
                    color: 'error'
                })
                this.closeDialog()
            }
        },
        finish() {
            this.$router.push(`/${i18n.locale}/menu/`);
            store.commit('setElectronicMoneyZeroMark', null)
            this.init()
        },
        checkUrl() {
            let comfirmUrl = location.href
            if (comfirmUrl.indexOf('?') !== -1 & comfirmUrl.indexOf('transactionId') !== -1) {
                store.commit('setIsFromLinePay', true)
                this.isFromLinePay = true
                this.showDialog(i18n.t('putIn.trading'), i18n.t('putIn.transaction-failed'), 30000)
                let objectURL = new URL(comfirmUrl)
                let params = objectURL.searchParams
                let urlParamsArray = []

                for (let pair of params.entries()) {
                    let obj = {
                        key: pair[0],
                        value: pair[1]
                    }
                    urlParamsArray.push(obj)
                }

                if (urlParamsArray && urlParamsArray[0] && urlParamsArray[0].value) {
                    let paymentType = LinePayType
                    if (urlParamsArray[0].key === 'jko_transactionId') {
                        paymentType = JKOPayType
                        this.message = i18n.t('putIn.jkopay-transaction')
                    }
                    let payload = {
                        client_id: store.getters.clientId,
                        payment_type: paymentType,
                        transactionId: urlParamsArray[0].value
                    }
                    let url = HostUrl + `/api/box/putin_confirm`
                    let self = this;
                    axios.post(url, payload, getAxiosConfig())
                        .then(function(response){
                            if (response.data.code === 0) {
                                if (response.data.door_id === 0) {
                                    self.message = i18n.t('putIn.payment-completed')
                                    self.dialogMessage = i18n.t('putIn.payment-completed')
                                    self.emptyDialog = true
                                    self.closeDialog()
                                    return 
                                }
                                store.commit('setTargetBoxId', response.data.box_id)
                                self.boxId = response.data.box_id
                                self.doorId = response.data.door_id
                                self.trackNo = response.data.track_no
                                self.payedAmount = response.data.pay_amount
                                self.putinTime = response.data.putin_time
                                self.putinUnixtime = response.data.putin_unixtime
                            } else if (response.data.code === 508) {
                                if (response.data.message === '付款完成') response.data.message = i18n.t('putIn.payment-completed')
                                    self.message = response.data.message
                                    self.dialogMessage = response.data.message
                                    self.emptyDialog = true
                                    self.closeDialog()
                            } else {
                                self.message = i18n.t('putIn.fail-trade-again')
                                store.commit('setSnackBar', { message: i18n.t('putIn.fail-trade-again'), color: 'error' })
                                self.closeDialog()
                            }
                        })
                        .catch(function(){
                            store.commit('setSnackBar', { message: i18n.t('electronicMoney.pay-fail'), color: 'error' })
                            self.closeDialog()
                        })
                }
            }
        },
        changeReceiver() {
            this.showRecevierDialog(i18n.t('putIn.recipient-number'), i18n.t('putIn.ok'), i18n.t('putIn.cancel'), true)
            this.closeReceiverDialogFunc = this.sendChangeReceiver
        },
        sendChangeReceiver() {
            let url = HostUrl + "/api/box/takeout/friend"
            let payload = {
                client_id: store.getters.clientId,
                friend_phone: this.receiverPhone,
                packages: {
                    [this.boxId]: [this.trackNo]
                }
            }
            this.showDialog(i18n.t('putIn.adding'), i18n.t('putIn.failed-add-recipient'), 30000)
            let self = this;
            axios.post(url, payload, getAxiosConfig())
                .then(function(response) {
                    if (response.data.code === 0) {
                        if (response.data.message === '請朋友登入取件') {
                            response.data.message = i18n.t('putIn.add-success') + response.data.packages[self.trackNo].phone + i18n.t('putIn.to-pick-up')
                        }
                        if (response.data.message === '請朋友註冊後，登入取件') {
                            response.data.message = i18n.t('putIn.add-success-register') + response.data.packages[self.trackNo].phone + i18n.t('putIn.log-to-pick-up')
                        }
                        store.commit('setSnackBar', { message: response.data.message, color: 'success' })
                        self.closeDialog()
                        self.$router.push(`/${i18n.locale}/`)
                    } else {
                        store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                        self.closeDialog()
                    }
                })
                .catch(function() {
                    store.commit('setSnackBar', { message: i18n.t('putIn.failed-add-recipient'), color: 'error' })
                    self.closeDialog()
                })
        },
        leave() {
            let reloadOne = this.reloadPage
            let message = this.dialogMessage
            this.init()
            this.$router.push(`/${i18n.locale}/`)
            if (reloadOne) {
                this.dialogMessage = message
                setTimeout(location.reload(), 1000)
            }
        }
    },
    activated() {
        this.checkUrl()

        // setTimeout(function(){
        //     store.commit('setTargetBoxId', '099001')
        // }, 1000)
    }
}
</script>

<style scoped>
    .nobull {
        list-style-type: none;
        padding-left: 0px;
    }
    .bold {
        font-weight: bold;
        font-size: 1.25rem;
    }
</style>
